.boxcat {
  margin-top: 45px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 600px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
 
  
 

}


.inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.image {

  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
 
}

.text {
  margin-top: 45px;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px; 
  text-align: center;
  font-family: 'GolosUIWebMedium';
}

.basket_nav {
  height: 23px;
  width: 23px;
  cursor: pointer;
}

.basket_nav:hover {
  filter: brightness(1.4); 
}