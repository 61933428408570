.hello-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .center-image {
    width: 45%; /* Измените размер изображения по вашему желанию */
  }

  .hello-typing-text {
    text-align: center;
    font-size: 1.5em;
  }