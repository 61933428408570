.custom-navbar {
    background-color: #ffffff;
    color: #fff;
  }

.button-nav {
  font-family: 'GolosUIWebMedium';
  background-color: #fffffff1;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  color: grey;
  
} 
 
.user-logo {
  height: 30px;
  width: 30px;
  margin-top: 5px;
  margin-right: 20px;

}
  
.home-img {
  height: 27px;
  width: 39px;
  margin-bottom: 5px;
}  
 
.catalog {
  height: 25px;
  width: 25px;

}

.home-img:hover {
  filter: brightness(1.1); 
}

.catalog:hover {
  filter: brightness(1.1); 
}

.user-logo:hover {
  filter: brightness(1.1); 
}